<template>
    <div>
        <p class="workplatform-title m-b-5">概况</p>
        <Row>
          <i-col span="8">
           <span class="title">客户 </span> {{change.advertiserName}}
          </i-col>
            <i-col span="8">
           <span class="title">编码 </span>{{change.code}}
          </i-col>
           <i-col span="8">
           <span class="title">变更创建时间 </span>{{change.createTime}}
          </i-col>
        </Row>

        <Row>
          <i-col span="8">
           <span class="title">变更状态 </span> {{change.statusName}}
          </i-col>
          <i-col span="8">
           <span class="title">审批通过时间 </span>
            <span v-if="change.approvedTime!==''">{{change.approvedTime}}</span>
            <span v-else>-</span>
          </i-col>
        </Row>

        <p class="workplatform-title m-t-10 m-b-5">变更明细</p>
        <change-detail :detail="changeDetail"></change-detail>

        <!-- 审批记录 -->
        <history :type="12" :relateId="otherParams" ></history>
  </div>
</template>

<script>
import ChangeDetail from '@/components/contractChange/ChangeDetail'
import History from '@/components/approval/History'
import { getVersionDetail } from '@/api/scp/contractchange'
import { getChangeFields } from '@/utils/contractChange'

export default {
  props: {
    baseId: {
      type: Number,
      required: true
    },
    otherParams: {
      type: Number,
      required: true
    }
  },
  components: {
    History, ChangeDetail
  },
  data () {
    return {
      id: this.baseId,
      change: { },
      changeDetail: []
    }
  },
  methods: {
    getDetail () {
      getVersionDetail({ versionId: this.otherParams }).then(res => {
        this.change = res
        this.changeDetail = getChangeFields(res.changeDetail)
      })
    }

  },
  created () {
    this.getDetail()
  },
  watch: {
    contractId (val) {
      this.id = this.baseId
      this.getDetail()
    }
  }

}
</script>
